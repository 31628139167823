import { Component, OnInit, ChangeDetectionStrategy } from "@angular/core";

@Component({
    selector: "s25-ng-occ-slider",
    template: ` <p>occ-slider works!</p> `,
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OccSliderComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
