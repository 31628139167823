import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    ViewEncapsulation,
} from "@angular/core";
import { TypeManagerDecorator } from "../../../../../main/type.map.service";
import { UserprefService } from "../../../../../services/userpref.service";
import { S25Datefilter } from "../../../../s25-dateformat/s25.datefilter.service";
import { S25Util } from "../../../../../util/s25-util";

@TypeManagerDecorator("occurrence-time")
@Component({
    selector: "occurrence-time",
    template: ` @if (init) {
        <div class="occ-time-wrapper">
            <div>
                {{ time }}
            </div>
            @if (isEndDate && spansMidnight) {
                <div>({{ date | dateFormat: dateFormat }})</div>
            }
        </div>
    }`,
    styles: [
        `
            .occ-time-wrapper {
                min-width: 149px;
            }
        `,
    ],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OccurrenceTimeComponent {
    @Input() date: string;
    @Input() isEndDate: boolean = false;
    @Input() occ: any;

    init: boolean = false;
    dateFormat: string;
    timeFormat: string;
    time: string;
    spansMidnight: boolean = false;

    constructor(
        private cd: ChangeDetectorRef,
        private elementRef: ElementRef,
    ) {}

    async ngOnInit() {
        const [dateFormat, timeFormat] = await Promise.all([
            UserprefService.getS25Dateformat(),
            UserprefService.getS25Timeformat(),
        ]);
        this.dateFormat = dateFormat;
        this.time = S25Datefilter.transform(this.date, timeFormat);
        if (this.isEndDate)
            this.spansMidnight = S25Util.date.getDate(this.occ.eventEnd) > S25Util.date.getDate(this.occ.eventStart);
        this.init = true;
        this.cd.detectChanges();
    }
}
